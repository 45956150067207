import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import OrderItem from "../../components/order-item/order-item";
import TitleComponent from "../../components/title/title";
import { ETheme } from "../../constants/theme";
import { ThemeContext } from "../../contexts/theme-context";
import { TicketContext } from "../../contexts/ticket-context";
import { fixDecimals } from "../../utils/helper";
import "./orders.scss";

export interface IOrdersProps {}

export default function Orders(props: IOrdersProps) {
  const { tickets, currency, serviceCostMaxFee, event,styleCustom } =
    useContext(TicketContext);
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const calculatorVat = useMemo(() => {
    const totalVat = tickets.reduce(
      (acc, item) =>
        acc +
        item.price * item.order * (item.vat ? item.vat : event.vatOfTicket),
      0
    );
    return totalVat;
  }, [tickets]);

  const calculatorServiceFee = useMemo(() => {
    const totalServiceFee = tickets.reduce(
      (acc, item) => acc + item.price * item.order * serviceCostMaxFee,
      0
    );
    return totalServiceFee;
  }, [tickets]);

  const calculatorSubTotal = useMemo(() => {
    const subTotal = tickets.reduce(
      (acc, item) =>
        acc +
        item.order *
          (event.includeVatToTicketPrice
            ? item.price +
              (item.vat
                ? item.price * item.vat
                : item.price * event.vatOfTicket)
            : item.price),
      0
    );
    return subTotal;
  }, [tickets]);

  const calculatorTotal = useMemo(() => {
    const subTotal = calculatorSubTotal;
    return subTotal;
  }, [tickets]);

  return (
    <>
      <div className="order-title">
        <TitleComponent text={t("title.order")} />
      </div>
      <div className="order" style={{backgroundColor: styleCustom?.secondaryColor &&  styleCustom?.secondaryColor, color: styleCustom?.tertiaryColor ? styleCustom?.tertiaryColor : `var(--text-primary)`}}>
        <div className="list">
          <div className="tickets">
            {tickets
              .filter((item) => item.count > 0)
              .map((item) => (
                <OrderItem key={item._id} ticket={item} />
              ))}
          </div>
        </div>
        <div className="divider"></div>
        <div className="list-price">
          <div className="item-price">
            <div className={`label ${theme !== ETheme.DARK && "label-light"}`}>
              {t("order.subTotal")}
            </div>
            <div className={`price ${theme !== ETheme.DARK && "price-light"}`}>
              <span className="concurrency">{currency}</span>&nbsp;
              {fixDecimals(calculatorSubTotal)}
            </div>
          </div>

          {!event.includeVatToTicketPrice && (
            <div className="item-price">
              <div
                className={`label ${theme !== ETheme.DARK && "label-light"}`}
              >
                {t("order.vat")}
              </div>
              <div
                className={`price ${theme !== ETheme.DARK && "price-light"}`}
              >
                <span className="concurrency">{currency}</span>&nbsp;
                {fixDecimals(calculatorVat)}
              </div>
            </div>
          )}

          {!!serviceCostMaxFee && (
            <div className="item-price">
              <div className="wrapperTooltip">
                <div
                  className={`label ${theme !== ETheme.DARK && "label-light"}`}
                >
                  {t("order.fee")} 
                </div>
              </div>
              <div
                className={`price ${theme !== ETheme.DARK && "price-light"}`}
              >
                <span className="concurrency">{currency}</span>&nbsp;
                {fixDecimals(calculatorServiceFee)}
              </div>
            </div>
          )}

          <div className="item-price">
            <div
              className={`label ${theme !== ETheme.DARK && "label-light"}`}
              // style={{ fontWeight: 700 }}
            >
              {t("order.total")}
            </div>
            <div className={`price ${theme !== ETheme.DARK && "price-light"}`}>
              <span className="concurrency">{currency}</span>&nbsp;
              {fixDecimals(
                event.includeVatToTicketPrice
                  ? calculatorTotal + calculatorServiceFee
                  : calculatorTotal + calculatorServiceFee + calculatorVat
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
