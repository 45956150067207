import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Android, AppStore } from "../../assets/images";
import Source from "../../constants/source";
import { ETheme } from "../../constants/theme";
import { ThemeContext } from "../../contexts/theme-context";
import "./thank-you.scss";
import { useSearchParams } from "react-router-dom";

export default function ThankYou() {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [userNameEmail, setUserNameEmail] = useState('')
  const onDownload = (type: string) => {
    const linkURL = document.createElement("a");

    if (type === "android") linkURL.href = Source.android;
    else linkURL.href = Source.appStore;

    linkURL.setAttribute("target", "_blank");
    document.body.appendChild(linkURL);
    linkURL.click();
  };

  useEffect(() => {
    const paramsAsObject = Object.fromEntries([...(searchParams as any)]);
    if (paramsAsObject.transactionId && paramsAsObject.email) {
      setUserNameEmail(paramsAsObject.email || '')
    }
  }, [searchParams]);

  return (
    <div className="thank-you-container">
      <div className="thank-you">
        <div
          className={`retrieve-title ${theme !== ETheme.DARK && "retrieve-title-light"
            }`}
        >
          {t("viewTicket")}
        </div>
      </div>

      <div className="download">
        <img
          src={Android}
          alt="Android"
          onClick={() => onDownload("android")}
        />
        <img
          src={AppStore}
          alt="AppStore"
          onClick={() => onDownload("appstore")}
        />
      </div>

      <div className="contract">
        <div className="important">{t("important")}</div>
        <div className="content-contract">
          <div className="text-contract">{t("emailOnboarding")}</div>
          <div className="text-contract email-contract">{userNameEmail}</div>
        </div>
      </div>

      <a
        className="website"
        target="_blank"
        href={Source.website}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {Source.website}
      </a>
    </div>
  );
}
