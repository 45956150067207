import React, { useContext } from "react";
import { ITicket } from "../../apis/types";
import { Dropdown, Trash } from "../../assets/images";
import { ETheme } from "../../constants/theme";
import { ThemeContext } from "../../contexts/theme-context";
import { TicketContext } from "../../contexts/ticket-context";
import { fixDecimals } from "../../utils/helper";
import "./order-item.scss";
import parse from "html-react-parser";

export interface IOrderItemProps {
  ticket: ITicket;
}

export default function OrderItem(props: IOrderItemProps) {
  const { ticket } = props;
  const { theme } = useContext(ThemeContext);
  const { handleChangeCountTicket, currency, event, styleCustom } =
    useContext(TicketContext);
  const arrayCount = React.useCallback(() => {
    return Array.from(Array(ticket.maximumTicketsPerCustomer).keys());
  }, []);
  return (
    <div className="order-item">
      <div className="select" style={{backgroundColor: styleCustom?.primaryColor && styleCustom?.primaryColor}}>
        <select
          value={ticket.order}
          onChange={(e) =>
            handleChangeCountTicket(ticket._id, undefined, +e.target.value)
          }
        >
          {arrayCount().map((value, index) => (
            <option value={value + 1} key={index}>
              {value + 1}
            </option>
          ))}
        </select>
        <div className="dropdown">
          <img src={Dropdown} />
        </div>
      </div>
      <div
        className={`ticketName ${theme !== ETheme.DARK && "ticketName-light"}`}
      >
        {parse(
          `${
            ticket.name
          }: <span className="concurrency">${currency}</span> ${fixDecimals(
            event.includeVatToTicketPrice
              ? ticket.order *
                  (ticket.price +
                    (ticket.vat
                      ? ticket.price * ticket.vat
                      : ticket.price * event.vatOfTicket))
              : ticket.order * ticket.price
          )} `
        )}
      </div>
      <div
        className="trash"
        onClick={() => handleChangeCountTicket(ticket._id, undefined, 0)}
      >
        <img src={Trash} />
      </div>
    </div>
  );
}
